import React from 'react';

const PrivacyPolicy = () => (
  <article>
        <header>
          <h1>Privacy Policy</h1>	</header>
        <div>
          <p>This website uses cookies. By using this website, you consent to the use of cookies and you agree to the following Terms and Conditions and Privacy Policy. Please read them carefully before using this website.</p>
          <p><strong>General Provisions</strong></p>
          <p>This website is owned and operated by Toni Lynn PhD, Enterprises, a Pennsylvania company. Our business mailing address is 12 North York Road, Hatboro, PA 19040.</p>
          <p>You must be at least eighteen years of age to use our website. Use of this website is at your own risk. We host our site on a reputable platform and take reasonable efforts to maintain and host the site. However, we make no explicit representations or warranties as to the safety or your individual use of the website. The terms and conditions contained on this page is subject to change at any time.</p>
          <p><strong>Intellectual Property Notice</strong></p>
          <p>All images, text, designs, graphics, trademarks and service marks are owned by and property of Toni Lynn PhD, Enterprises, or the properly attributed party. It is a violation of federal law to use any of our intellectual property in whole or in part, and modification of any materials contained on this site is illegal and may be prosecuted to the fullest extent permissible should we choose to do so, including asking for financial penalties (damages) and/or an injunction forcing you to stop using our intellectual property immediately.</p>
          <p>You may use our intellectual property with clear and obvious credit back to our site, as well links back to the page where the materials, designs, images, text, quote or post is located when it is appropriate to do so. However, you may never claim any of our intellectual property as your own or your unique creation, even with attribution.</p>
          <p><strong>Your Communications</strong></p>
          <p>Any communications made through our ‘contact,’ blog, blog comments, newsletter sign up or other related pages, or directly to our phones or mailing or email addresses is not held privileged or confidential and is subject to viewing and distribution by third parties. We own any and all communications displayed on our website, servers, comments, emails or other media as allowed by United States law, and will not give credit or pay royalties for unsolicited user-generated content such as blog comments or emails. For more information on when and how we store and use your communications or any data provided by you in those communications, please refer to our Privacy Policy on this page.</p>
          <p>We maintain a right to republish any submission in whole or in part as reasonably necessary in the course of our business. You agree not to submit any content or communications that could be illegal or serve an unlawful purpose, including, but not limited to communications that are potentially libelous or maliciously false, obscene, abusive, negligent or otherwise harmful or inappropriate.</p>
          <p><strong>Disclaimers</strong></p>
          <p>Our website and related materials are provided for educational and informational use only. You agree to indemnify and hold harmless our website and company for any direct or indirect loss or conduct incurred as a result of your use of our website and any related communications, including as a result of any consequences incurred from technological failures such as a payment processor error(s) or system failure(s).</p>
          <p>While we may reference certain results, outcomes or situations on this website, you understand and acknowledge that we make no guarantee as to the accuracy of third party statements contained herein or the likelihood of success for you as a result of these statements or any other statements anywhere on this website. If you have a medical, legal or financial question, you should consult a medical professional, lawyer or CPA and/or CFP respectively. We expressly disclaim any and all responsibility for any actions or omissions you choose to make as a result of using this website, related materials, products, courses or the materials contained herein.</p>
          <p>This website is updated on a regular basis and while we try to make accurate statements in a timely and effective manner, we cannot guarantee that all materials and related media contained herein are entirely accurate, complete or up to date. You expressly acknowledge and understand that any information or knowledge you gain as a result of using this website is used at your own risk. If you should see any errors or omissions and would like to let us know, please email drtonicoaches@gmail.com.</p>
          <p><strong>Affiliates</strong></p>
          <p>This site may use affiliate links to sell certain products or services. We disclaim any and all liability as a result of your purchase through one of these links. We will use reasonable efforts to notify you when and where we have placed affiliate links in addition to this disclaimer located in these Terms &amp; Conditions. You accept express liability for any and all consequences or benefits of clicking the affiliate links contained on this website or related communications.</p>
          <p><strong>Termination</strong></p>
          <p>If at any time we feel you have violated these Terms and Conditions, then we shall immediately terminate your use of our website and any related communications as we deem appropriate. It is within our sole discretion to allow any user’s access of our website, and we may revoke this access at any time without notice, and if necessary, block your IP address from further visits to our site(s).</p>
          <p><strong>Refunds &amp; Payment Collection</strong></p>
          <p>We take your investment seriously, and we’d appreciate if you took our investment of time and resources into your success seriously too.</p>
          <p>Due to the nature of the services and/or products provided, refunds will not be given unless otherwise specified in writing. &nbsp;Toni Lynn PhD, Enterprises reserves the right to charge 1.5% interest per day upon any outstanding sum left unpaid on or after 14 calendar days from due date.</p>
          <p>Example:</p>
          <p>$200 due March 1</p>
          <p>$201.50 due March 15</p>
          <p>$203.03 due March 16</p>
          <p>Sent to Collections April 15</p>
          <p>After 30 days of outstanding payment, Toni Lynn PhD, Enterprises reserves the right to send you to collections, upon which you will owe the total amount of any outstanding payments plus any collection costs including reasonable attorney’s fees.</p>
          <p><strong>Entire Agreement</strong></p>
          <p>Before you register with our website or make any purchases therefrom, you will be asked to consent to our Privacy Policy. If you have consented, or once you do consent, the terms of the Privacy Policy together with these Terms and Conditions, and the information contained herein constitutes the entire agreement between site users and our company relating to the use of this website.</p>
          <p><strong>Severability</strong></p>
          <p>If any part of these terms and conditions or our privacy policy is deemed unlawful and/or unenforceable, all other provisions contained herein will remain in full force and effect.</p>
          <p><strong>Law and Jurisdiction</strong></p>
          <p>These terms, conditions and privacy policy are governed by and construed in accordance with United States law. Any dispute arising out of or related to the information contained herein is subject to adjudication in the state of Pennsylvania, in the United States of America.</p>
          <p><strong>Consent</strong></p>
          <p>By using our website, you hereby consent to our Terms and Conditions of Use.</p>
          <p>If you require any more information or have any questions about our Terms and Conditions of website use, or our Privacy Policy, please feel free to contact us by email drtonicoaches@gmail.com.</p>
          <p><strong>Privacy Policy</strong></p>
          <p>Everyone at Toni Lynn PhD, Enterprises respect your privacy. This Privacy Policy is designed to explain how we collect, use, share, and protect the personal information you provide to us when you access our website, purchase our goods or services, or engage with us on social media, as well as your own rights to the information we collect.</p>
          <p>Please read this Privacy Policy carefully. We will alert you to any changes to this Policy by changing the “last updated” date at the top of this Policy. Any changes become effective immediately upon publication on our website, and you waive specific notice of any changes to the Policy by continuing to use and access our site(s). We encourage you to review this Privacy Policy periodically, when you use our website for any purpose or engage with us on social media. You are deemed to have accepted any changes to any revised Privacy Policy by your continued use of our website after the revised Privacy Policy is posted.</p>
          <p><strong>INFORMATION THAT WE COLLECT</strong></p>
          <p>We collect a variety of information from you when you visit our website, make purchases, or interact with us on social media. By accepting this Privacy Policy, you are specifically consenting to our collection of the data described below, to our use of the data, to the processing of this data, and to our sharing of the data with third party processors as needed for our legitimate business interests. The information we collect may include:</p>
          <p><strong>Personal Data:</strong> Personal Data is information that can be used to identify you specifically, including your name, shipping address, email address, telephone number or demographic information like your age, gender, or hometown. You consent to giving us this information by providing it to us voluntarily on our website or any mobile application. You provide some of this information when you register with or make purchases from our website. You may also provide this information by participating in various activities associated with our site, including responding to blogs, contacting us with questions, or participating in group training. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain benefits from our website or from making purchases.</p>
          <p><strong>Derivative Data:</strong> Derivative data is information that our servers automatically collect about you when you access our website, such as your IP address, browser type, the dates and times that you access our website, and the specific pages you view. If you are using a mobile application, our servers may collect information about your device name and type, your phone number, your country of origin, and other interactions with our application.</p>
          <p><strong>Financial Data:</strong> Financial data is data that is related to your payment method, such as credit card or bank transfer details. We collect financial data in order to allow you to purchase, order, return or exchange products or services from our website and any related mobile apps. We store limited financial data. Most financial data is transferred to our payment processor, Stripe, and you should review these processors’ Privacy Policy to determine how they use, disclose and protect your financial data. As a courtesy, Stripe’s Privacy Policy can be found here: https://stripe.com/us/privacy.</p>
          <p><strong>Social Networking Data:</strong> We may access personal information from social networking sites and apps, including Facebook, Instagram, Linkedin, Twitter, Snapchat or other social networking sites or apps not named specifically here, which may include your name, your social network username, location, email address, age, gender, profile picture and any other public information. If you do not want us to access this information, please go to the specific social networking site and change your privacy settings.</p>
          <p><strong>Mobile Device Data:</strong> If you use our website via a mobile device or app, we may collect information about your mobile device, including device ID, model and manufacturer, and location information.</p>
          <p><strong>Other data:</strong> On occasion, you may give us additional data in order to enter into a contest or giveaway or to participate in a survey. You will be prompted for this information and it will be clear that you are offering this kind of information in exchange for an entry into such a contest or giveaway.</p>
          <p><strong>HOW WE USE YOUR INFORMATION</strong></p>
          <p>Your information allows us to offer you certain products and services, including the use of our website, to fulfill our obligations to you, to customize your interaction with our company and our website, and to allow us to suggest other products and services we think might interest you. We generally store your data and transmit it to a third party for processing. However, to the extent we process your data, we do so to serve our legitimate business interests (such as providing you with the opportunity to purchase our goods or services and interact with our website or mobile app).</p>
          <p>Specifically, we may use the information and data described above to:</p>
          <p>1. Create and administer your account; and</p>
          <p>2. Deliver any products or services purchased by you to you; and</p>
          <p>3. Correspond with you; and</p>
          <p>4. Process payments or refunds; and</p>
          <p>5. Contact you about new offerings that we think you will be interested in; and</p>
          <p>6. Interact with you via social media; and</p>
          <p>7. Send you a newsletter or other updates about our company or website; and</p>
          <p>8. Deliver targeted advertising; and</p>
          <p>9. Request feedback from you; and</p>
          <p>10. Notify you of updates to our product and service offerings; and</p>
          <p>11. Resolve disputes and troubleshoot any problems; and</p>
          <p>12. Administer contests or giveaways; and</p>
          <p>13. Generate a profile that is personalized to you, so that future interactions with our website will be more personal; and</p>
          <p>14. Compile anonymous statistical data for our own use or for a third party’s use; and</p>
          <p>15. Assist law enforcement as necessary; and</p>
          <p>16. Prevent fraudulent activity on our website or mobile app; and</p>
          <p>17. Analyze trends to improve our website and offerings.</p>
          <p><strong>WHY WE DISCLOSE YOUR INFORMATION</strong></p>
          <p>We may share your information with third parties in certain situations. In particular, we may share your data with third party processors as needed to serve our legitimate business interests, which include administration of our website, administration of your account, entering into contracts with you, communicating with you, taking orders for goods or services, delivering our goods and services, identifying trends, protecting the security of our company and website, and marketing additional goods and services to you. The legal basis for our disclosure of your data is both your Consent to this Privacy Policy and our own right to protect and promote our legitimate business interests.</p>
          <p>The following are specific reasons why we may share your information.</p>
          <p><strong>Third Party Processing:</strong> We may disclose your information to third parties who assist us with various tasks, including payment processing, hosting services, email delivery and customer service. For more information, see the “Third Party Processing” Section below.</p>
          <p><strong>By Law:</strong> We may share your data as required by law or to respond to legal process, including a subpoena, or as necessary to protect the rights, property, and safety of others. This includes sharing information with other parties to prevent or address fraud and to avoid credit risks.</p>
          <p><strong>To Protect Our Company:</strong> We may use your information to protect our company, including to investigate and remedy any violations of our rights or policies. We may also disclose your information as reasonably necessary to acquire and maintain insurance coverage, manage risks, obtain financial or legal advice, or to exercise or defend against legal claims.</p>
          <p><strong>Affiliates:</strong> We may share your personal information with our business affiliates who promote our product(s) or service(s) for a commission fee. We require our affiliates to honor this Privacy Policy. They are not allowed to spam you and must disclose they are an affiliate for us. If they do not do so, they are in violation of their affiliate terms and this Privacy Policy, and any violation of this nature should be reported to dtonicoaches@gmail.com.</p>
          <p><strong>Advertisers:</strong> We may use third party advertising companies to run and manage our ads, such as Google Ads, to produce ads that appears when you visit our Website or mobile app. These companies may use information about your visit to our website and other websites that are contained in web cookies (as described below) to offer you personalized advertisements about goods and services that might interest you. We cannot control the activities of, such other advertisers or web sites. You should consult the respective Privacy Policies of these third-party advertisers for more detailed information on their practices as well as for instructions about how to opt-out of certain practices.</p>
          <p><strong>Other Third Parties:</strong> We may share information with advertisers, our investors, or other third parties for the purpose of conducting general business analysis. If we do so, we will make reasonable efforts to inform you, if required by law.</p>
          <p><strong>Sale or Bankruptcy:</strong> In the event that our company is sold, goes out of business or enters bankruptcy, your information may be an asset that is transferred to a third party successor. Such a successor is not bound by our Privacy Policy and may have its own. You will be notified in the event our Company is sold, goes out of business or enters bankruptcy.</p>
          <p><strong>Interaction with others:</strong> If you interact with others on our website or mobile app, such as participating in a group chat or a group online course, other users may have access to some of your data, including your name, profile picture, and your history of interaction with our website, such as prior comments or posts.</p>
          <p><strong>Online postings:</strong> When you post online, your posts may be viewed by others, and we may distribute your comments outside the website.</p>
          <p><strong>External Links:</strong> Our website may include hyperlinks to other websites not controlled by us. We suggest you exercise caution when clicking on a hyperlink. Although we use reasonable care in including a hyperlink on our own web page, we do not regularly monitor the websites of these third parties, are not responsible for any damage or consequences you suffer by using these hyperlinks. We are not bound by the Privacy Policies of any third party website that you access by a hyperlink, nor are they bound by ours. We encourage you to read the Policies of those third party websites before interacting with them or making purchases. They may collect different information and by different methods than we do.</p>
          <p><strong>Other purposes:</strong> We may disclose your personal data as necessary to comply with any legal obligation or to protect your interests, or the vital interests of others or our company.</p>
          <p><strong>TRACKING TECHNOLOGIES</strong></p>
          <p><strong>Cookies, Log Files and Web Beacons:</strong> Like many other Web sites, we makes use of log files. These files merely logs visitors to the site – usually a standard procedure for hosting companies and a part of hosting services’ analytics. The information inside the log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses and other such information are not linked to any information that is personally identifiable.</p>
          <p>We also use cookies – small text files sent to us by your computer – and web beacons to store certain information. We may use cookies to authenticate your identity, to determine if you are logged onto our website, for personalization, for security, for targeted advertising, or for analysis of the performance of our website and services. For example, cookies allow us to recommend blog posts to you based on what you have read on our site in the past. We use cookies that are not specific to your account but unique enough to allow us to analyze general trends and use, and to customize your interaction with our website.</p>
          <p>Most browsers are set to accept cookies by default. In addition, when you first encounter our website, you will be asked to “consent to cookies.” If you wish to disable cookies, you may do so through your individual browser options. However, this may affect your ability to use or make purchases from our website. More detailed information about cookie management with specific web browsers can be found at the browsers’ respective websites. </p>
          <p>What Are Cookies? By continuing to use our website and not disabling cookies on your browser, you are consenting to our use of cookies in accordance with the terms of this policy.</p>
          <p>In addition, we may use third-party software to post advertisements on our website or mobile app to oversee marketing or email campaigns, or manage other company initiatives. These third party softwares may use cookies or similar tracking technology. We have no control over these third parties or their use of cookies. For more information on opting out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.</p>
          <p><strong>WEBSITE ANALYTICS</strong></p>
          <p>We may partner with third party analytic companies, including Google Analytics. These analytic companies may also use cookies or other tracking technologies to analyze visitors’ use of our website or mobile app to determine the popularity of the content, and better understand online activity. We do not transfer personal information to these third party vendors. However, in order to access our website, you must consent to the collection and use of your information by these third party analytic companies. You should review their Privacy Policy and contact them directly if you have questions. If you do not want any information to be collected and used by tracking technologies, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.</p>
          <p><strong>PROCESSING YOUR INFORMATION</strong></p>
          <p>For the most part, we do not process your information in-house, but give it to third party processors for processing. For example, when PayPal takes your payment information, they are a third party processor. They process your payment and remit the funds to us. So in many instances, it will be necessary for us to transmit your information to a third party processor, as we do not have the capability to perform these functions. More detail on third party processing is detailed below.</p>
          <p>However, we may, from time to time, process your data internally. The legal basis for this processing is both your consent to the processing, and our need to conduct our legitimate business interests. Our purposes in processing this information, if we do, is to administer, maintain, and improve our website and offerings, to enter into contracts with you, to fulfill the terms of those contracts, to keep records of our transactions and interactions, to be able to provide you with goods and services, to comply with our legal obligations, to obtain professional advice, and to protect the rights and interests of our company, our customers (including you), and any third parties. We may process the following data:</p>
          <p>1. Data associated with your account, such as your name, address, email address and payment information</p>
          <p>2. Data about your usage of our website, such as your IP address, geographical information, and how long you accessed our website and what you viewed.</p>
          <p>3. Data related to your personal profile, such as your name, address, profile picture, interests and hobbies, or employment details.</p>
          <p>4. Data that you provide us in the course of using our services.</p>
          <p>5. Data that you post on our website, such as comments or responses to blogs.</p>
          <p>6. Data that you submit to us when you make an inquiry regarding our website or offerings.</p>
          <p>7. Data related to your transactions with us, including your purchase of our goods or services. This information may include contact details and payment information.</p>
          <p>8. Data that you provide to us when you subscribe to our emails or newsletters, including your email address and contact information.</p>
          <p>9. Data that you submit to us via correspondence, such as when you email us with questions.</p>
          <p>10. Any other data identified in this policy, for the purpose of complying with our legal obligations, or to protect the vital interests of you or any other natural person.</p>
          <p><strong>INTERNATIONAL DATA</strong></p>
          <p>Our website is hosted by servers located in the U.S. Therefore, if you reside in the European Union, some of your data will be transferred internationally to those servers. Transfers will be protected by appropriate safeguards, namely the EU-US Privacy Shield. More information on the Privacy Shield can be found at: https://www.privacyshield.gov/welcome.</p>
          <p><strong>DATA RETENTION</strong></p>
          <p>We retain personal data as long as it is needed to to conduct our legitimate business purposes or to comply with our legal obligations, or until you ask us to delete your data. For example, we will retain certain personal information indefinitely for the purposes of maintaining your account, unless and until you delete your account. Data that we gather for a specific and particular purpose, such as assisting law enforcement or analyzing trends, will not be kept for longer than is necessary for that particular purpose. Data that is no longer needed by us for any of the purposes listed above will be permanently deleted.</p>
          <p>You may request that we delete your data at any time. However, note that we cannot control the retention policies of third parties. If you wish to have any third parties, including those to whom we’ve transmitted your data, delete that data, you will need to contact those third parties directly. You may request from us a list of all third parties to whom we have transmitted your data.</p>
          <p><strong>SECURITY OF YOUR INFORMATION</strong></p>
          <p>We take all reasonable steps to protect your personal data and keep your information secure. We use recognized online secure payment systems and implement generally accepted standards of security to protect against personal data loss or misuse. However, no security measure is foolproof, and no method of data transmission can be guaranteed against interception or misuse. We cannot guarantee complete security of any information you transmit to us.</p>
          <p>By consent to this Privacy Policy, you acknowledge that your personal data may be available, via the internet, around the world. We cannot prevent the use or misuse of your data by other parties.</p>
          <p>We will notify you of promptly any known breach of our security systems or your data which might expose you to serious risk.</p>
          <p><strong>CHILDREN</strong></p>
          <p>This website is not designed for use by children under age 18, and we do not knowingly solicit personal data from anyone under age 18. If you are under age 18, do not access or use our website or related products or services. If you become aware that we have collected data of anyone under the age of 18, please contact us so that we may delete that data.</p>
          <p><strong>YOUR RIGHTS</strong></p>
          <p>You have certain rights with respect to your personal data, as outlined below. Note that we may charge you a reasonable fee for actions that you ask us to take with respect to your data. In addition, we reserve the right to request that you provide us with evidence of your identity before we take any action with respect to the exercise of your data rights. Further, your rights may be restricted or nullified to the extent they conflict with our compelling business interests, the public interest, or the law.</p>
          <p><strong>Update Account Information:</strong> You have the right to update or change any information you have provided to us. To update or delete your information, please contact us at drtonicoaches@gmail.com.</p>
          <p><strong>Confirm Personal Data and Its Use:</strong> You have the right to request that we confirm what data we hold about you, and for what purposes. You also have the right to confirmation of whether we process your data or deliver your data to third party processors, and for what purposes. We will supply you with copies of your personal data unless doing so would affect the rights and freedoms of others.</p>
          <p><strong>Change Consent:</strong> You have the right to change your consent to our use of your information. In such cases, we may require you to delete your account with us, as described above, and you may not have full access to our website.</p>
          <p><strong>Request a Copy of Data:</strong> You have the right to request a digital copy of the data that we hold about you. Your first request for a copy of your personal data will be provided free of charge; subsequent requests will incur a reasonable fee.</p>
          <p><strong>Transfer Your Data:</strong> You have the right to request that we gather and transfer your data to another controller, in a commonly used and machine readable format, unless doing so would cause us an undue burden.</p>
          <p><strong>Delete All Data:</strong> You have the right to request that we delete all data that we hold about you, and we must delete such data without undue delay. There are exceptions to this right, such as when keeping your data is required by law, is necessary to exercise the right of freedom of expression and information, is required for compliance with a legal obligation, or is necessary for the exercise or defense of legal claims. Such a request may result in a termination of your account with us and you may have limited or no use of our website.</p>
          <p><strong>Emails And Communications:</strong> You may opt out of receiving future email correspondence from us by checking the appropriate box when you register for the account or make a purchase. You may change your communication settings by contacting drtonicoaches@gmail.com.</p>
          <p><strong>Marketing Communications:</strong> You may opt out of receiving any third party marketing communications or having your personal information used for marketing purposes. You may do this by emailing drtonicoaches@gmail.com.</p>
          <p><strong>Processing:</strong> You may, in some circumstances restrict the processing of your data, such as when you contest the accuracy of your data or when you have objected to processing, pending the verification of that objection. When processing has been restricted, we will continue to store your data but will not pass it on to third party processors without your consent, or as necessary to comply with legal obligations or protect your rights or those of others or our company. In addition, you may opt out of any processing of your data altogether. Note however that doing so may result in the termination of your account and loss of access to our website.</p>
          <p><strong>Complaints:</strong> You have the right to complain to a supervising authority if you believe we are misusing your data or have violated any of your rights under this Privacy Policy or applicable law.</p>
          <p><strong>CALIFORNIA PRIVACY RIGHTS</strong></p>
          <p>The State of California has established its own unique regulations that apply to California residents. If you reside in California, you have the right to obtain from us, once per year and free of charge, information regarding what information we disclose to third party marketers, and the names and addresses of each third party to whom we disclose your data. If you are a California resident and would like to make such a request, please use the contact information listed below.</p>
          <p>If you are a California resident and under the age of 18, you have the right to request that we remove any data that you publicly post on our website. To request removal of your data, please use the contact information listed below. Note that while we will remove your data that is publicly posted on our website, we may not be able to completely remove that data from our systems.</p>
          <p><strong>Newsletter Privacy</strong></p>
          <p>We offer the opportunity for you to volunteer certain information to us that is used for email and marketing purposes. This information includes, but is not limited to, your name and email. You will have an opportunity to unsubscribe from any future communications via email, but we reserve the right to maintain a database of past email subscribers. We reserve the right to use this information as reasonably necessary in our business and as provided by law. Your information will be shared with reasonably necessary parties for the ordinary course of conducting our business, such as through Facebook ads or Google Pay Per Click marketing campaigns.</p>
          <p><strong>Severability</strong></p>
          <p>If any part of these terms, conditions and privacy policy is deemed unlawful and/or unenforceable, all other provisions contained herein will remain in full force and effect.</p>
          <p><strong>Entire Agreement</strong></p>
          <p>The information contained herein constitutes the entire agreement between site users and our company relating to the use of this website.</p>
          <p><strong>Law and Jurisdiction</strong></p>
          <p>These terms, conditions and privacy policy are governed by and construed in accordance with United States law. Any dispute arising out of or related to the information contained herein is subject to adjudication in the state of Pennsylvania.</p>
          <p><strong>Contact Information</strong></p>
          <p><strong>Email:</strong> hello@drtonicoaches.com</p>
          <p><strong>Mailing address:</strong> 12 North York Rod, Hatboro, PA 19040<br /></p>
        </div>
      </article>
);

export default PrivacyPolicy;