import React, { PureComponent } from "react"
import Layout from '../components/layout';
import PrivacyPolicy from '../components/privacy-policy';

export default class PrivacyPolicyPage extends PureComponent {
  render() {
    return (
      <Layout>
        <PrivacyPolicy/>
      </Layout>
    )
  }
}
